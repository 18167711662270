import http from '@/common/utils/http'

const RJURL = 'RuiJin'

/** 获取故障统计 */
export function statistics(data) {
  return http({
    url: `fault/statistics`,
    method: 'post',
    data
  })
}

/** 获取故障定义列表帅选 */
export function getFaultDefinitionSearch(data, data3) {
  return http({
    url: `/fault/definition/definitionSearch/${data}/${data3}`,
    method: 'get'
  })
}

/** 电梯运行数据 */
export function getElevatorRunDataStatistics(data) {
    let url = data.villageId?'wuye/getElevatorEventStatisticsGroupByElevator':'wuye/getElevatorRunDataStatistics'
  return http({
    url: url,
    method: 'post',
    data
  })
}

/** 每日停靠运行数据 */
export function getElevatorStopDataStatistics(data) {
  return http({
    url: `wuye/getElevatorRunDataStatistics`,
    method: 'post',
    data
  })
}

/** 物业故障统计 */
export function getFaultDataStatistics(data) {
  let url = data.villageId?'wuye/getFaultDataStatisticsGroupByElevator':'wuye/getFaultDataStatistics'
  return http({
    url: url,
    method: 'post',
    data
  })
}

/** 物业不文明统计 */
export function getUnCivilizedDataStatistics(data) {
  let url = data.villageId?'wuye/getUnCivilizedDataStatisticsGroupByElevator':'wuye/getUnCivilizedDataStatistics'
  return http({
    url: url,
    method: 'post',
    data
  })
}

// 故障类型统计
export function proportionOfElevatorFailures(data) {
  return http({
    url: `${RJURL}/ProportionOfElevatorFailures`,
    method: 'post',
    data
  })
}

export function failureWorkOrderTrendChart(data) {
  return http({
    url: `${RJURL}/failureWorkOrderTrendChart`,
    method: 'post',
    data
  })
}

export function trendOfUncivilizedBehaviorNew(data) {
  return http({
    url: `${RJURL}/TrendOfUncivilizedBehavior`,
    method: 'post',
    data
  })
}


export function numberOfMaintenanceWorkOrders(data) {
  return http({
    url: `${RJURL}/numberOfMaintenanceWorkOrders`,
    method: 'post',
    data
  })
}

export function statisticsWorkOrderStatusProportion(data) {
  return http({
    url: `${RJURL}/statisticsWorkOrderStatusProportion`,
    method: 'post',
    data
  })
}

export function trendYearOfHistoricalMaintenance(data) {
  return http({
    url: `${RJURL}/trendYearOfHistoricalMaintenance`,
    method: 'post',
    data
  })
}
export function statisticsOfTrappedPeopleRescue(data) {
  return http({
    url: `${RJURL}/statisticsOfTrappedPeopleRescue`,
    method: 'post',
    data
  })
}

export function theHistoryOfSingleLabor(data) {
  return http({
    url: `${RJURL}/theHistoryOfSingleLabor`,
    method: 'post',
    data
  })
}


/** 运行统计 */
export function getRunCountInfo(data) {
  return http({
    url: `${RJURL}/getRunCountInfo`,
    method: 'post',
    data
  })
}

/** 运行统计导出 */
export function exportRunCountInfo(data) {
  return http({
    url: `${RJURL}/exportRunCountInfo`,
    method: 'post',
    responseType: 'blob',
    data
  })
}

/** 不文明行为趋势 */
export function getUncivilizedTrend(data) {
  return http({
    url: `wuye/getUncivilizedTrend`,
    method: 'post',
    data
  })
}

/** 维保工单趋势 */
export function getMaintenanceTrend(data) {
  return http({
    url: `wuye/getMaintenanceTrend`,
    method: 'post',
    data
  })
}

/** 急修工单趋势 */
export function getRepairTrend(data) {
  return http({
    url: `wuye/getRepairTrend`,
    method: 'post',
    data
  })
}

/** 人流量 */
export function pedestrianFlowStatistics(data) {
  return http({
    url: `peopleFlowStatisticsController/pedestrianFlowStatistics`,
    method: 'post',
    data
  })
}

/** 小区人流量 */
export function villagePedestrianFlowStatistics(data) {
  return http({
    url: `peopleFlowStatisticsController/pedestrianFlowStatisticsByVillage`,
    method: 'post',
    data
  })
}

/** 楼层人流量 */
export function floorPedestrianFlowStatistics(data) {
  return http({
    url: `peopleFlowStatisticsController/pedestrianFlowStatisticsGroupByFloor`,
    method: 'post',
    data
  })
}


