<template>
    <div>
        <MXPageLayout>
            <div slot="filter">
                <Row>
                    <Form style="display:flex;justify-content:end" ref="formInline" :model="fromData" inline :label-width="10" label-position="right">
                        <FormItem>
                            <Select v-model="fromData.villageId" placeholder="全部小区" clearable style="width:180px">
                                <Option v-for="(item, index) in villageFormItemSelectList" :key="index" :value="item.v_village_id">
                                    {{ item.v_village_name }}</Option>
                            </Select>
                        </FormItem>
                        <FormItem>
                            <DatePicker @on-change="fromData.startTime = $event" :value="fromData.startTime" format="yyyy-MM-dd" type="date" placeholder="开始时间" style="width: 180px">
                            </DatePicker>
                        </FormItem>
                        <FormItem>
                            <DatePicker @on-change="onChangeEndTime" :value="fromData.endTime" format="yyyy-MM-dd" type="date" placeholder="结束时间" style="width: 180px">
                            </DatePicker>
                        </FormItem>
                        <FormItem>
                            <Select v-model="fromData.orderType" placeholder="展示方式" style="width:140px">
                                <Option :value="item.value" v-for="(item, index) in DisplayTypeDataList" :key="index">{{ item.label }}</Option>
                            </Select>
                        </FormItem>
                        <Button type="primary" shape="circle" icon="ios-search" @click="searchList()">搜索
                        </Button>
                    </Form>
                </Row>
            </div>
            <div slot="title">
                {{pageTitle}}
            </div>
            <div slot="content">
                <div class="content">
                    <div class="echart-box" id="E_zkcl" :style="
          `width:100%;height:600px;zoom:${zoom};transform:scale(${1 /
            zoom});transform-origin:0 0`
        "></div>
                </div>
            </div>
        </MXPageLayout>
    </div>
</template>
<script>
import { getUnCivilizedDataStatistics } from '@/api/ststistics/index.js'
import { searchVillageSelectList } from '@/api/home/index.js'
import Statistics from '@/pages/Statistics/statistics'
import * as echarts from 'echarts'
export default {
    data() {
        return {
            pageTitle: '不文明行为',
            DisplayTypeDataList: [{
                    value: 'asc',
                    label: '升序'
                },
                {
                    value: 'desc',
                    label: '降序'
                }
            ],
            model1: '',
            fromData: {
                startTime: this.formatDate(-30),
                endTime: this.formatDate(0),
                orderType: 'asc',
                orderBy: 'total',
                villageId: ''
            },
            option: {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999'
                        }
                    }
                },
                legend: {
                    data: ['全部', '反复阻挡门', '电动车入梯'],
                    textStyle: {
                        color: '#fff',
                        fontSize: 16,
                        fontWeight: 'normal'
                    },
                    selected: {
                        全部: true,
                        反复阻挡门: false,
                        电动车入梯: false
                    }
                },
                xAxis: [{
                    type: 'category',
                    data: ['外围分布', '瑞金总院'],

                    axisLabel: {
                        rotate: 15,
                        interval: 'auto',
                    }
                }],
                yAxis: [{
                        type: 'value',
                        name: '',
                        min: 0,
                        // max: 250,
                        // interval: 50,
                        axisLabel: {
                            textStyle: {
                                fontSize: 16,
                                color: '#fff'
                            }
                        }
                    },
                    {
                        type: 'value',
                        name: '',
                        min: 0,
                        // max: 25,
                        // interval: 5,
                        axisLabel: {
                            formatter: '{value} %',
                            textStyle: {
                                fontSize: 16,
                                color: '#fff'
                            }
                        }
                    }
                ],
                series: [],
                // dataZoom: [{ // 这是一个 dataZoom 组件，可以在 x 轴上进行区域缩放
                //     type: 'slider', // 这里设置 dataZoom 的类型为 slider
                //     start: 0, // 数据窗口范围的起始百分比
                //     end: 90, // 数据窗口范围的结束百分比
                //     show: true
                // }, {
                //     // 没有下面这块的话，只能拖动滚动条，鼠标滚轮在区域内不能控制外部滚动条(当前滑块可控制)
                //     type: 'inside',
                //     realtime: true,
                //     start: 0,
                //     end: 100,
                // }]
            },
            zoom: 1,
            villageFormItemSelectList: [],
            selectedLegend: '全部'
        }
    },
    computed: {
        theme() {
            return this.$store.getters.theme
        },
        user() {
            return this.$store.state.user
        }
    },
    mixins: [Statistics],
    mounted() {
        // this.searchList()
        this.reqVillageList()
    },
    watch: {
        'fromData.villageId': {
            handler(val) {
                this.updatePageTitle()
            },
            immediate: true,
        },
        'fromData.orderBy'(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.searchList()
            }
        },
        theme(newVal, oldVal) {
            if (newVal != oldVal) {
                this.searchList()
            }
        }
    },
    methods: {
        searchList() {
            let params = this.formatTimeParams('startTime', 'endTime', this.fromData)
            getUnCivilizedDataStatistics(params).then(res => {
                let myChart = this.$echarts.init(document.getElementById('E_zkcl'))
                this.chart = myChart
                if (res.info) {
                    res.info.series.splice(-3)

                    let showBar;
                    let categories;
                    let _data;

                    showBar = res.info.series.find(item => item.name == this.selectedLegend)

                    if (this.fromData.orderType == 'asc') {
                        let idx = showBar.data.findIndex(it => it == 0)
                        categories = res.info.categories.slice(idx, res.info.categories.length).concat(res.info.categories.slice(0, idx))
                        _data = showBar.data.sort(function(a, b) { return a - b })
                    } else {
                        categories = res.info.categories
                        _data = showBar.data
                    }

                    this.option.xAxis[0].data = categories.map(item => {
                        return {
                            value: item,
                            textStyle: {
                                color: this.theme == 'dark' ? '#fff' : '',
                                fontSize: 12
                            }
                        }
                    })
                    let arr = []
                    let arr2 = []

                    res.info.series.forEach(item => {
                        arr.push(item.name)

                        arr2.push({
                            name: item.name,
                            type: 'bar',
                            barWidth: item.data.length > 10 ? 'auto' : 30,
                            tooltip: {
                                valueFormatter: function(value) {
                                    return value
                                }
                            },
                            color: new echarts.graphic.LinearGradient(0, 0, 1, 1, [{
                                    offset: 0,
                                    color: '#0ff'
                                },
                                {
                                    offset: 1,
                                    color: '#00aff8'
                                }
                            ]),
                            data: _data
                        })
                    })
                    this.option.legend.data = arr
                    this.option.series = arr2

                    // let dataLength = _data ? _data.length : 0
                    // const dataZoomEnd = dataLength > 13 ? (13 / dataLength) * 100 : 100
                    // this.option.dataZoom[0].show = dataZoomEnd != 100
                    // this.option.dataZoom[0].end = dataZoomEnd
                    // this.option.dataZoom[1].end = dataZoomEnd

                }
                this.option.yAxis.forEach(item => {
                    item.axisLabel.color = this.theme == 'dark' ? '#fff' : ''
                })

                myChart.setOption(this.option)
                let that = this
                myChart.on('legendselectchanged', function(params) {
                    if (params.name == '全部' || params.name == '全部(比率)') {
                        that.option.legend.selected['全部'] = true
                        that.option.legend.selected['反复阻挡门'] = false
                        that.option.legend.selected['电动车入梯'] = false
                        that.selectedLegend = '全部'
                        that.fromData.orderBy = 'total'
                    }

                    if (
                        params.name == '反复阻挡门' ||
                        params.name == '反复阻挡门(比率)'
                    ) {
                        that.option.legend.selected['全部'] = false
                        that.option.legend.selected['反复阻挡门'] = true
                        that.option.legend.selected['电动车入梯'] = false
                        that.selectedLegend = '反复阻挡门'
                        that.fromData.orderBy = 'blockDoor'
                    }

                    if (
                        params.name == '电动车入梯' ||
                        params.name == '电动车入梯(比率)'
                    ) {
                        that.option.legend.selected['全部'] = false
                        that.option.legend.selected['反复阻挡门'] = false
                        that.option.legend.selected['电动车入梯'] = true
                        that.selectedLegend = '电动车入梯'
                        that.fromData.orderBy = 'electricBike'
                    }

                    myChart.hideLoading()
                    myChart.setOption(that.option)
                })
            })
        },
        reqVillageList() {
            searchVillageSelectList(this.buildParams({})).then(res => {
                this.villageFormItemSelectList = res.info


                if (this.villageFormItemSelectList.length > 0) {
                    this.fromData.villageId = this.villageFormItemSelectList[0]['v_village_id']
                }

                this.searchList()
            })
        },
        buildParams(params) {
            params.vProjectId = this.user.user.v_project_id
            return params
        },
        getModuleName() {
            return '不文明行为'
        },
        onChangeEndTime(e) {
            this.fromData.endTime = e + ' 23:59:59'
        }
    }
}
</script>
<style scoped>
.home {
    padding: 10px 30px 0 10px;
}

::v-deep .ivu-select-selected-value {
    color: #fff !important;
}
</style>