<template>
    <div class="mx-page-layout">
        <div class="filter-wrapper">
            <slot name="filter"></slot>
        </div>
           <div class="title-wrapper">
            <slot name="title"></slot>
        </div>
        <div class="content-wrapper" id="contentWrapper">
            <slot name="content"></slot>
        </div>
    </div>
</template>
<script>
export default {
    name: 'MXPageLayout',
    components: {

    },
    props: [],
    data() {
        return {

        }
    },
    mounted() {},
    methods: {

    },
    watch: {

    }
}
</script>
<style lang="scss" scoped>
  .mx-page-layout {
    display:flex;
    flex-direction:column;
    padding:20px 0;
  }

  .content-wrapper {
    flex:1;
  }

  .title-wrapper div{
    text-align:center;
    color:#fff;
    font-size:20px;
    margin-bottom:20px;
  }

  .filter-wrapper {
    padding:0 30px;
  }
</style>