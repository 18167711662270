import MXPageLayout from '@/pages/components/mx-biz-components/mx-page-layout'
export default {
    data() {
        return {
            pageTitle: '',

        }
    },
    computed: {

    },
    components: {
        MXPageLayout
    },
    mounted() {
        this.addChartResizeListener()
    },
    watch: {
        'fromData.villageId': {
            handler(val) {
                this.updatePageTitle()
            },
            immediate: true,
        },
        'fromData.elevatorCode': {
            handler(val) {
                this.updatePageTitle()
            },
            immediate: false,
        },
    },
    methods: {
        addChartResizeListener() {
            let elementResizeDetectorMaker = require("element-resize-detector");
            //监听元素变化
            let erd = elementResizeDetectorMaker();
            let that = this;
            erd.listenTo(document.getElementById('contentWrapper'), function(element) {
                if (that.chart)
                that.chart.resize()
            })
        },
        updatePageTitle() {
            if (this.fromData.villageId && this.fromData.villageId.length) {
                let find = this.villageFormItemSelectList.find((village) => {
                    return village.v_village_id == this.fromData.villageId
                })
                if (find) {
                    this.pageTitle = `${find.v_village_name}${this.getModuleName()}`
                }
            } else {
                this.pageTitle = `全部小区${this.getModuleName()}`
            }
        },
    }
}